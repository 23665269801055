<template>
  <div>
    <b-card>
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Buscar</label>
            <b-form-input v-model="searchTerm" placeholder="Buscar" type="text" class="d-inline-block" />
          </div>
        </b-form-group>
      </div>
      <vue-good-table :columns="columns" :rows="rows" :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }">
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'action'" class="text-nowrap">
            <RouterLink class="user__more"
              :to="{ path: `/auditory/types-of-credit/${props.row.id}`}">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary"
                class="btn-icon rounded-circle">
                <feather-icon icon="EyeIcon" />
              </b-button>
            </RouterLink>
          </div>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <b-form-select v-model="pageLength" :options="['3', '5', '10']" class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
              <span class="text-nowrap ">{{ props.total }} registros totales</span>
            </div>

            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
        <template>

        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>
    
<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BButton
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton
  },
  data() {
    return {

      pageLength: 3,
      dir: false,
      rows: [],
      columns: [
        {
          label: 'Lote Asignación',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'Burcar lote asignación',
          },
        },
        {
          label: 'Fecha Asignación',
          field: 'assignmentDate',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar fecha asignación',
          },
        },
        {
          label: 'Creditos Asignados',
          field: 'assignmentsConcepts',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar facturas asignadas',
          },
        },
        {
          label: 'Valor Asignado',
          field: 'assignmentsValue',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar valor asignado',
          },
        },
        {
          label: 'Creditos Pendientes',
          field: 'assigmentPendding',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar facturas pendientes',
          },
        },
        {
          label: 'Valor Pendiente',
          field: 'penddingValue',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar valor pendiente',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    fetchListAssignment()
    {
      this.$http.get('/assignment/list/analyst/' + this.userData.id).then((response) => {
        this.rows = response.data.assignmentBatches
        this.totalRows = response.data.total
      })
    }
  },
  created() {
    this.fetchListAssignment()
  },
  directives: {
    Ripple,
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>